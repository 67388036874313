/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

type state = {
    sidebarShow: 'responsive' | boolean;
    asideShow: boolean;
    darkMode: boolean;
    token: string | null;
    logoutUri: string;
    mainUrl: string;
    logoCenterUrl: string;
    logoCenterHeight: number;
    logoLeftUrl: string;
    logoLeftHeight: number;
    companyName: string;
    keycloak_client_id: string;
    appUrl: string;
    graphQlUrl: string;
};

const appUrl = process.env.REACT_APP_URL ?? '';

const initialState: state = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
    token: null,
    logoutUri: process.env.NODE_ENV == 'production' ? `${appUrl}/logout` : 'http://localhost:3000/logout',
    mainUrl: process.env.REACT_APP_MAIN_URL ?? '',
    logoCenterUrl: process.env.REACT_APP_LOGO_CENTER_URL ?? '',
    logoCenterHeight: parseInt(process.env.REACT_APP_LOGO_CENTER_HEIGHT ?? '78'),
    logoLeftUrl: process.env.REACT_APP_LOGO_LEFT_URL ?? '',
    logoLeftHeight: parseInt(process.env.REACT_APP_LOGO_LEFT_HEIGHT ?? '58'),
    companyName: process.env.REACT_APP_COMPANY_NAME ?? '',
    keycloak_client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
    appUrl: appUrl,
    graphQlUrl: process.env.REACT_APP_GRAPHQL_URL ?? '',
};

type args = { type?: string; [key: string]: any };

const changeState = (state = initialState, { type, ...rest }: args) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest };
        default:
            return state;
    }
};

const store = createStore(changeState);
export default store;

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<state> = useSelector;
